import { RouteComponentProps } from '@reach/router';
import React from 'react';
import { FormPage } from 'DesignSystem/Layout/Pages';
import { ViewMergeUserMapping } from 'shared/MergeIntegration';

export const ViewUserMapping: React.FC<RouteComponentProps<{
  integrationId: string;
}>> = ({ integrationId }) => {
  return (
    <FormPage
      title="View User Mapping"
      breadcrumbs={[
        { to: '../../../..', label: 'Configure' },
        { to: '../../..', label: 'Workday' },
        { label: 'View User Mapping' },
      ]}
    >
      <ViewMergeUserMapping integrationId={integrationId} type="workday" />
    </FormPage>
  );
};
