import { RouteComponentProps } from '@reach/router';
import React from 'react';
import { ConfigureMergeUserMapping } from 'shared/MergeIntegration';

export const ConfigureUserMapping: React.FC<RouteComponentProps<{
  integrationId: string;
}>> = ({ integrationId }) => {
  return (
    <ConfigureMergeUserMapping
      type="workday"
      breadcrumbs={[
        { to: '../../..', label: 'Configure' },
        { to: '../..', label: 'Workday' },
        { label: 'User Mapping' },
      ]}
      integrationId={integrationId}
    />
  );
};
