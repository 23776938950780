import {
  QueryKey,
  useInfiniteQuery,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { useProgram } from 'contexts/program';
import {
  MergeIntegration,
  QueryBlueprintPresets,
  SupportedIntegration,
  UserMapping,
  UserMappingConfig,
} from 'models/merge-integration';
import {
  fetchIntegrations,
  getUserMappingConfig,
  getUserMappingQueryPresets,
  getUserMappings,
  getUserMappingSelectableMergeAttributes,
} from 'services/api-merge-integration';
import { InfiniteQueryResponse } from './common';

export const getMergeIntegrationsQueryKey = (
  programId: number,
  type: string
): QueryKey => ['merge-integrations', programId, 'config', type];

export function useMergeIntegrations(
  type: SupportedIntegration
): UseQueryResult<MergeIntegration[]> {
  const { id: programId } = useProgram();

  return useQuery(getMergeIntegrationsQueryKey(programId, type), () =>
    fetchIntegrations(programId, type)
  );
}

export function useUserMappingConfiguration(
  integrationId: string | undefined,
  options?: UseQueryOptions<UserMappingConfig | null>
): UseQueryResult<UserMappingConfig | null> {
  const { id: programId } = useProgram();

  return useQuery(
    ['merge-integrations', programId, 'user-mapping', integrationId],
    () =>
      integrationId ? getUserMappingConfig(programId, integrationId) : null,
    {
      enabled: !!integrationId,
      ...options,
    }
  );
}

export function useUserMappingQueryPresets(): UseQueryResult<
  QueryBlueprintPresets
> {
  const { id: programId } = useProgram();

  return useQuery(
    ['merge-integrations', programId, 'user-mapping-presets'],
    () => getUserMappingQueryPresets(programId)
  );
}

export function useUserMappingSelectableMergeAttributes(): UseQueryResult<
  string[]
> {
  const { id: programId } = useProgram();

  return useQuery(
    [
      'merge-integrations',
      programId,
      'user-mapping-selectable-merge-attributes',
    ],
    () => getUserMappingSelectableMergeAttributes(programId)
  );
}

export function useUserMappings(
  integrationId: string,
  pageSize = 30
): InfiniteQueryResponse<UserMapping> {
  const { id: programId } = useProgram();

  const { data, ...rest } = useInfiniteQuery(
    ['merge-integrations', programId, 'user-mappings', integrationId],
    ({ pageParam }) =>
      getUserMappings(programId, integrationId, pageParam, pageSize),
    {
      enabled: !!integrationId,
    }
  );

  const batchData = data?.pages.map((batch) => (batch ? batch.data : []));
  const flatData = batchData && batchData.flat(1);

  return {
    data: flatData || [],
    meta: data?.pages[0].meta,
    ...rest,
  };
}
