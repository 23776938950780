import React from 'react';

import { useProgram } from 'contexts/program';
import { Flex } from 'DesignSystem/Layout/Flex';
import { Text } from 'DesignSystem/Typography';
import { useValueSuggestionsQuery } from 'hooks/audience';
import { DateTime } from 'luxon';
import { ComplexExpression } from 'models/expression';
import { RecurringTrigger, timeZones } from 'models/journeys/journey';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { capitalizeWords } from 'utility/strings';
import { pluralize } from 'utility/text';
import {
  getJourneyTriggerSchedule,
  TriggerScheduleParams,
} from 'services/api-journey';
import { useQuery } from 'react-query';
import { editableTextToQuery } from '../../../JourneyDrawer/shared/AudienceSelect';
import { isEveryone as everyone, parseCriterion } from './utils';

function useInitiationTriggerSchedule({
  type,
  ...params
}: TriggerScheduleParams) {
  const programId = useProgram().id;
  return useQuery({
    queryKey: [type, params],
    queryFn: async () => {
      const data = await getJourneyTriggerSchedule(programId, {
        type,
        ...params,
      });
      const timezoneValue = timeZones.find(
        (tz) =>
          tz.name === params.timeZone || tz.group.includes(params.timeZone)
      );
      return {
        ...data,
        formattedSchedule: data.data.schedule.map((at) => {
          const run = DateTime.fromMillis(at).setZone(params.timeZone);
          return `${run.toFormat('EEEE, MMMM d, yyyy h:mma')} ${
            timezoneValue?.label
          } Timezone`;
        }),
      };
    },
    keepPreviousData: true,
    staleTime: 1000 * 60 * 60,
  });
}

export function RecurringNextRuns(
  trigger: Pick<RecurringTrigger, 'timeZone' | 'timeOfDay'>
): JSX.Element {
  const { data: scheduleData, isFetching } = useInitiationTriggerSchedule({
    type: 'recurring',
    timeZone: trigger.timeZone,
    timeOfDay: trigger.timeOfDay,
  });

  const nextThreeRuns = (scheduleData?.formattedSchedule ?? []).slice(0, 3);

  return (
    <Flex start style={{ gap: 12 }}>
      <Flex column alignStart start>
        {nextThreeRuns.map((formattedRun) => (
          <Text
            className={{ Body: true }}
            key={formattedRun}
            data-testid="testing"
          >
            {formattedRun}
          </Text>
        ))}
      </Flex>
      {isFetching && <LoadingSpinner size="small" />}
    </Flex>
  );
}
export const RecurringBody: React.FC<{
  trigger: RecurringTrigger;
  criterion?: ComplexExpression;
}> = ({ trigger, criterion }) => {
  const { id: programId } = useProgram();
  const { isLoading, data: options = [] } = useValueSuggestionsQuery(
    programId,
    'group',
    trigger.triggerCriterion?.values.join('|') ?? '*',
    editableTextToQuery('*'),
    undefined,
    true
  );
  const audienceNameById = options.reduce<Record<string, string>>(
    (acc, option) => ({
      ...acc,
      [option.value]: option.label,
    }),
    {}
  );

  const triggerCriterion =
    trigger.triggerCriterion &&
    `Audience is ${trigger.triggerCriterion.values
      .map(
        (value) =>
          audienceNameById[value] ??
          capitalizeWords(value.replace(/_/g, ' '), { ios: 'iOS' })
      )
      .join(', ')}`;
  const isEveryone = !criterion || everyone(criterion);
  const parsedCriterion = !isEveryone && criterion && parseCriterion(criterion);

  if (isLoading)
    return (
      <Flex>
        <LoadingSpinner />
      </Flex>
    );

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: 'auto 1fr',
        gap: '4px 8px',
        alignItems: 'start',
      }}
    >
      <Text className={{ Semibold: true }}>Start Type:</Text>
      <Text className={{ Body: true }}>Daily</Text>

      {triggerCriterion && (
        <>
          <Text className={{ Semibold: true }}>Trigger:</Text>
          <Text className={{ Body: true }}>{triggerCriterion}</Text>
        </>
      )}

      <Text className={{ Semibold: true }}>Filter Members:</Text>
      {isEveryone ? (
        <Text className={{ Body: true }}>None, everyone is eligible</Text>
      ) : (
        parsedCriterion && (
          <Text className={{ Body: true }}>
            {parsedCriterion.count} {pluralize(parsedCriterion.count, 'filter')}{' '}
            applied
          </Text>
        )
      )}

      <Text className={{ Semibold: true }}>Next Three Run Times:</Text>
      <RecurringNextRuns
        timeOfDay={trigger.timeOfDay}
        timeZone={trigger.timeZone}
      />
    </div>
  );
};
