import {
  TooltipContent,
  TooltipRoot,
  TooltipTrigger,
} from '@socialchorus/shared-ui-components';
import React, { useState } from 'react';
import { Flex } from 'DesignSystem/Layout/Flex';
import { useUserMappings } from 'hooks/merge-integration';
import { MergeEmployee, SupportedIntegration } from 'models/merge-integration';
import { User } from 'models/user';
import { InfiniteList } from 'shared/InfiniteList';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { Modal } from 'shared/Modal';
import { getIntegrationLabel } from '../label';
import styles from './styles.module.css';

type PreviewUserMappingProps = {
  type: SupportedIntegration;
  integrationId: string | undefined;
};

export const ViewMergeUserMapping: React.FC<PreviewUserMappingProps> = ({
  type,
  integrationId,
}) => {
  const [modalContent, setModalContent] = useState<string>();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    data,
    meta,
    hasNextPage,
    fetchNextPage,
    isLoading,
    isFetchingNextPage,
  } = useUserMappings(integrationId ?? '');

  const openModal = (content: string) => () => {
    setModalContent(content);
    setIsModalOpen(true);
  };

  const integrationLabel = getIntegrationLabel(type);

  if (isLoading) {
    return <LoadingSpinner />;
  }
  return (
    <>
      <Flex spread>
        <Flex column>Firstup ID</Flex>
        <Flex column>{integrationLabel} ID</Flex>
      </Flex>
      <hr />
      <InfiniteList
        itemCount={meta?.totalRecords || 0}
        itemHeight={40}
        height={600}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        isLoading={isLoading}
        isFetchingNextPage={isFetchingNextPage}
      >
        {(index: number) => {
          const userMapping = data[index];
          return (
            <Flex spread className={styles.UserMappingTableRow}>
              <Flex column>
                <TooltipRoot>
                  <TooltipTrigger>
                    <button
                      type="button"
                      className={styles.UserMappingRowContent}
                      onClick={openModal(
                        prettyPrintFirstupUser(userMapping.firstupUser)
                      )}
                    >
                      {userMapping.firstupUser.id}
                    </button>
                  </TooltipTrigger>
                  <TooltipContent description="Click to view attributes" />
                </TooltipRoot>
              </Flex>

              <Flex column>
                <TooltipRoot>
                  <TooltipTrigger>
                    <button
                      type="button"
                      className={styles.UserMappingRowContent}
                      onClick={openModal(
                        prettyPrintMergeEmployee(userMapping.mergeEmployee)
                      )}
                    >
                      {userMapping.mergeEmployee.remoteId}
                    </button>
                  </TooltipTrigger>
                  <TooltipContent description="Click to view attributes" />
                </TooltipRoot>
              </Flex>
            </Flex>
          );
        }}
      </InfiniteList>

      <Modal
        title="Preview attributes"
        showTitle={false}
        showModal={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      >
        <pre>
          <code>{modalContent}</code>
        </pre>
      </Modal>
    </>
  );
};

function prettyPrintMergeEmployee(employee: MergeEmployee) {
  return JSON.stringify(employee, null, 2);
}

function prettyPrintFirstupUser(user: User) {
  return JSON.stringify(
    {
      id: user.id,
      displayName: user.displayName,
      email: user.email,
    },
    null,
    2
  );
}
