import { RouteComponentProps, useLocation } from '@reach/router';
import { DateTime } from 'luxon';
import React from 'react';
import { usePermissions } from 'contexts/permissions';
import { Section } from 'DesignSystem/Form';
import { FormPage } from 'DesignSystem/Layout/Pages';
import { useMergeIntegrations } from 'hooks/merge-integration';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import {
  MergeAdministrativeActions,
  MergeLinkConfigurationButton,
  MergeUserMappingConfigPreview,
} from 'shared/MergeIntegration';
import styles from './styles.module.css';

export const ConfigureIntegration: React.FC<RouteComponentProps> = () => {
  const { pathname, origin } = useLocation();
  const { permissions } = usePermissions();
  const { data, isLoading } = useMergeIntegrations('workday');

  const existingIntegration = data?.[0];

  return (
    <FormPage
      title="Workday"
      breadcrumbs={[{ to: '..', label: 'Configure' }, { label: 'Workday' }]}
    >
      <div className={styles.SectionsContainer}>
        <Section title="Link Account">
          {isLoading && <LoadingSpinner />}
          {!isLoading &&
            (existingIntegration?.status === 'active' ? (
              <>
                <span className={styles.ConfigurationSectionHeading}>
                  Workday is configured for this program on{' '}
                  <strong>
                    {DateTime.fromJSDate(
                      new Date(existingIntegration.updatedAt)
                    ).toFormat('MMM dd, yyyy')}
                  </strong>
                  .
                </span>
              </>
            ) : (
              <MergeLinkConfigurationButton type="workday" />
            ))}
        </Section>

        {!isLoading && existingIntegration && (
          <Section
            title="User Mapping Settings"
            href={`${existingIntegration.id}/user-mapping`}
          >
            <MergeUserMappingConfigPreview
              integrationId={existingIntegration.id}
            />
          </Section>
        )}

        {permissions.mergeWorkdayAdminActionsAccess &&
          !isLoading &&
          existingIntegration && (
            <MergeAdministrativeActions
              type="workday"
              userMappingPreviewPath={
                new URL(
                  `${pathname}/${existingIntegration.id}/user-mapping/preview`,
                  origin
                ).pathname
              }
            />
          )}
      </div>
    </FormPage>
  );
};
