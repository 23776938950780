import { RouteComponentProps, Router } from '@reach/router';
import React from 'react';
import { WithPermission } from 'shared/WithPermission';
import { ConfigureIntegration } from './configure';
import { ConfigureUserMapping } from './configure/UserMapping';
import { ViewUserMapping } from './ViewUserMapping';

export const MergeWorkdayIntegration: React.FC<RouteComponentProps> = () => {
  return (
    <WithPermission
      permissions={['configureMergeWorkdayAccess']}
      operation="all"
    >
      <Router>
        <ConfigureIntegration path="/" />
        <ConfigureUserMapping path="/:integrationId/user-mapping" />
        <ViewUserMapping path="/:integrationId/user-mapping/preview" />
      </Router>
    </WithPermission>
  );
};
