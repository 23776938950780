import camelcaseKeys from 'camelcase-keys';
import snakecaseKeys from 'snakecase-keys';
import {
  MergeIntegration,
  QueryBlueprintPresets,
  UserMapping,
  UserMappingConfig,
} from '../models/merge-integration';
import { request } from './api-shared';
import { PaginationData } from './common';

const apiRoot = `${process.env.REACT_APP_BOSSANOVA_DOMAIN}`;

export async function fetchIntegrations(
  programId: number,
  type: string
): Promise<MergeIntegration[]> {
  const url = `${apiRoot}/v2/tenants/program:${programId}/merge_integration/integrations/${type}`;
  const response = await request(url);

  if (response.status === 200) {
    return camelcaseKeys(await response.json(), { deep: true });
  }

  throw new Error(`Error fetching Merge integrations: ${response.status}`);
}

type CreateMergeLinkTokenResponse = {
  id: string;
  linkToken: string;
};

export async function createMergeLinkSession(
  programId: number,
  type: string
): Promise<CreateMergeLinkTokenResponse> {
  const url = `${apiRoot}/v2/tenants/program:${programId}/merge_integration/configure`;
  const response = await request(url, {
    method: 'POST',
    body: JSON.stringify({ type }),
  });

  if (response.status === 200) {
    return camelcaseKeys(await response.json(), { deep: true });
  }

  throw new Error(`Error creating Merge Link session: ${response.status}`);
}

type CompleteMergeLinkSessionPayload = {
  integrationId: string;
  publicToken: string;
};

export async function completeMergeLinkSession(
  programId: number,
  { integrationId, publicToken }: CompleteMergeLinkSessionPayload
): Promise<void> {
  const url = `${apiRoot}/v2/tenants/program:${programId}/merge_integration/configure/callback`;
  const response = await request(url, {
    method: 'POST',
    body: JSON.stringify(snakecaseKeys({ integrationId, publicToken })),
  });

  if (response.status % 200 > 100) {
    throw new Error(
      `Error completing setup using Merge Link: ${response.status}`
    );
  }
}

export async function getUserMappingConfig(
  programId: number,
  integrationId: string
): Promise<UserMappingConfig | null> {
  const url = `${apiRoot}/v2/tenants/program:${programId}/merge_integration/configure/user_mappings?integrationId=${integrationId}`;
  const response = await request(url);

  if (response.status === 200) {
    return camelcaseKeys(await response.json(), { deep: true });
  }

  if (response.status === 204) {
    return null;
  }

  throw new Error(`Error fetching user mapping config: ${response.status}`);
}

export async function upsertUserMappingConfig(
  programId: number,
  payload: Pick<
    UserMappingConfig,
    'integrationId' | 'firstupAttributeQueryBlueprint' | 'mergeAttribute'
  >
): Promise<void> {
  const url = `${apiRoot}/v2/tenants/program:${programId}/merge_integration/configure/user_mappings`;
  const response = await request(url, {
    method: 'POST',
    body: JSON.stringify(snakecaseKeys(payload, { deep: true })),
  });

  if (response.status % 200 > 100) {
    throw new Error(`Error upserting user mapping config: ${response.status}`);
  }
}

export async function getUserMappingQueryPresets(
  programId: number
): Promise<QueryBlueprintPresets> {
  const url = `${apiRoot}/v2/tenants/program:${programId}/merge_integration/configure/user_mappings/query_presets`;
  const response = await request(url);

  if (response.status === 200) {
    const data = camelcaseKeys(await response.json(), { deep: true }) as ({
      key: string;
    } & Record<string, unknown>)[];

    return Object.fromEntries(
      data.map(({ key, ...rest }) => [key, rest])
    ) as QueryBlueprintPresets;
  }

  throw new Error(`Error fetching user mapping config: ${response.status}`);
}

export async function getUserMappingSelectableMergeAttributes(
  programId: number
): Promise<string[]> {
  const url = `${apiRoot}/v2/tenants/program:${programId}/merge_integration/configure/user_mappings/merge_attributes`;
  const response = await request(url);

  if (response.status === 200) {
    return response.json();
  }

  throw new Error(
    `Error fetching selectable merge attributes: ${response.status}`
  );
}

export async function getUserMappings(
  programId: number,
  integrationId: string,
  page: number,
  size: number
): Promise<{ data: UserMapping[]; meta: PaginationData }> {
  const url = new URL(
    `${apiRoot}/v2/tenants/program:${programId}/merge_integration/integrations/${integrationId}/user_mappings`
  );
  if (page) {
    url.searchParams.append('pageNum', page.toString());
  }
  if (size) {
    url.searchParams.append('pageSize', size.toString());
  }

  const response = await request(url.toString());

  if (response.status === 200) {
    return camelcaseKeys(await response.json(), { deep: true });
  }

  throw new Error(`Error fetching user mappings: ${response.status}`);
}
