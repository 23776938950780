import { useProgram } from 'contexts/program';
import {
  useAudiencesInfiniteQuery,
  useValueSuggestionsQuery,
} from 'hooks/audience';
import { useTopicsInfiniteQuery } from 'hooks/topics';
import React from 'react';
import { useQuery, UseQueryResult } from 'react-query';
import {
  DashboardFilterMapping,
  fetchDashboardFilters,
} from 'services/api-insights';
import { useContentsInfiniteQuery } from '../../../../../hooks/content';
import { useJourneysInfiniteQuery } from '../../../../../hooks/journeys/journeys';
import { editableTextToQuery } from '../../Journey/JourneyDrawer/shared/AudienceSelect';
import { DashboardFilterDropdown } from '../components/DashboardFilterDropdown';
import { generateDateValues } from '../components/filter-utils';
import { useInitiativesInfiniteQuery } from '../../../../../hooks/initiatives';

type DashboardFilterProps = {
  onChange: (filter: string, values: string[]) => void;
  onClose?: (dashboardName: string) => void;
};

export const DashboardFilterWrapper: React.FC<
  DashboardFilterProps & {
    queryHook: unknown;
    queryHookProps?: { [key: string]: unknown };
    filterKey: string;
    label: string;
    valueAccessor?: string;
    valueTransform?: (data: {
      [key: string]: string;
    }) =>
      | {
          label: string;
          value: string;
        }
      | undefined;
    multiSelect?: boolean;
    defaultNullValue?: string[];
    initialValues?: string[];
    hasClearButton?: boolean;
  }
> = ({
  onChange,
  queryHook,
  queryHookProps,
  filterKey,
  label,
  valueAccessor,
  valueTransform = (data) => ({
    label: data?.name,
    value: data?.name,
  }),
  multiSelect = true,
  initialValues,
  defaultNullValue,
  hasClearButton,
}) => {
  const onFilterChange = React.useCallback(
    (values: string[]) => {
      if (multiSelect) {
        onChange(filterKey, values);
      } else if (!values.length) {
        onChange(filterKey, defaultNullValue ?? []);
      } else {
        onChange(filterKey, [values[values.length - 1]]);
      }
    },
    [multiSelect, onChange, filterKey, defaultNullValue]
  );
  return (
    <DashboardFilterDropdown
      onChange={onFilterChange}
      options={[]}
      label={label}
      multiSelect={multiSelect}
      queryHook={queryHook}
      queryHookProps={queryHookProps}
      valueAccessor={valueAccessor}
      valueTransform={valueTransform}
      initialValues={initialValues}
      hasClearButton={hasClearButton}
    />
  );
};

export const DashboardFilter: React.FC<{
  filter: DashboardFilterMapping;
  onChange: (filter: string, values: string[]) => void;
  onClose?: (dashboardName: string) => void;
  initialValues?: string[];
}> = ({ filter, onChange, initialValues }) => {
  const useUserDepartmentQuery = ({ programId }: { programId: number }) => {
    const response = useValueSuggestionsQuery(
      programId,
      'department',
      '*',
      editableTextToQuery('*')
    );
    return {
      data: response.data,
      isLoading: response.isLoading,
    };
  };

  const dashboardFilters: Record<
    string,
    {
      queryHook: unknown;
      queryHookProps?: { [key: string]: unknown };
      filterKey: string;
      label: string;
      valueTransform?: (data: {
        [key: string]: string;
      }) => {
        label: string;
        value: string;
      };
      multiSelect?: boolean;
      defaultNullValue?: string[];
      hasClearButton?: boolean;
    }
  > = {
    content: {
      queryHook: useContentsInfiniteQuery,
      filterKey: 'content_id',
      valueTransform: (data: { [key: string]: string }) => {
        return {
          label: `${data.id} ${data.title}`,
          value: data.id.toString(),
        };
      },
      label: 'Content',
      defaultNullValue: ['-1'],
    },
    topics: {
      queryHook: useTopicsInfiniteQuery,
      filterKey: 'topic_id',
      label: 'Topic',
      multiSelect: false,
      valueTransform: (data: { [key: string]: string }) => {
        return {
          label: data.name,
          value: data.id.toString(),
        };
      },
      defaultNullValue: ['-1'],
    },
    initiatives: {
      queryHook: useInitiativesInfiniteQuery,
      filterKey: 'initiative_tag_id',
      label: 'Initiative',
      multiSelect: false,
      valueTransform: (data: { [key: string]: string }) => {
        return {
          label: data.name,
          value: data.id.toString(),
        };
      },
    },
    audiences: {
      queryHook: useAudiencesInfiniteQuery,
      filterKey: 'audience_id',
      label: 'Audience',
      multiSelect: false,
      valueTransform: (data: { [key: string]: string }) => {
        return {
          label: data.title,
          value: data.id.toString(),
        };
      },
      defaultNullValue: ['-1'],
    },
    timeRange: {
      queryHook: () => {
        return {
          data: generateDateValues('month'),
          isLoading: false,
        };
      },
      hasClearButton: false,
      multiSelect: false,
      valueTransform: (data: { [key: string]: string }) => {
        return {
          label: data.label,
          value: data.value,
        };
      },
      filterKey: 'month_year_filterable',
      label: 'Time Range',
    },
    userDepartment: {
      queryHook: useUserDepartmentQuery,
      filterKey: 'attribute_department',
      label: 'User Department',
      valueTransform: (data: { [key: string]: string }) => {
        return {
          label: data.value,
          value: data.value,
        };
      },
    },
    journey: {
      queryHook: useJourneysInfiniteQuery,
      queryHookProps: {
        sortBy: 'created_at',
        sortDirection: 'desc',
        editingState: ['paused', 'active'],
      },
      filterKey: 'campaign_id',
      label: 'Journey',
      multiSelect: false,
      valueTransform: (data: { [key: string]: string }) => {
        return {
          label: data.name,
          value: `${data?.id?.toString()}|${data?.currentActivationId?.toString()}`,
        };
      },
    },
    dateRange: {
      queryHook: () => {},
      label: 'Start Date',
      filterKey: '',
      multiSelect: false,
      valueTransform: (data: { [key: string]: string }) => {
        return {
          label: data.value,
          value: data.value,
        };
      },
    },
    journeyDatePicker: {
      queryHook: () => {},
      label: 'Journey Entry Date',
      filterKey: 'journey_entry_start_date',
      multiSelect: false,
      valueTransform: (data: { [key: string]: string }) => {
        return {
          label: data.value,
          value: data.value,
        };
      },
    },
  };

  if (!dashboardFilters[filter.name]) return <></>;

  return (
    <DashboardFilterWrapper
      onChange={onChange}
      {...dashboardFilters[filter.name]}
      initialValues={initialValues}
    />
  );
};

export type DashboardFilterObj = {
  filters: string[];
  parameters: string[];
};

const transformDashboardFilters = (
  filters: DashboardFilterMapping[]
): DashboardFilterObj => {
  const parameter_keys: string[] = [];
  const filter_keys: string[] = [];
  filters.forEach((filter) => {
    if (filter.filter_type === 'parameter') {
      parameter_keys.push(filter.name);
    } else {
      filter_keys.push(filter.name);
    }
  });
  return {
    filters: filter_keys,
    parameters: parameter_keys,
  };
};

export const useDashboardFilters = (
  dashboardId: string
): UseQueryResult<DashboardFilterObj> => {
  const programId = useProgram().id;
  return useQuery({
    queryKey: ['dashboardFilters', dashboardId],
    queryFn: () =>
      fetchDashboardFilters({ programId, dashboardId }).then((res) => {
        return transformDashboardFilters(res.data);
      }),
    staleTime: 2 * 60,
  });
};

export const useDashboardFiltersQuery = (
  dashboardId: string
): UseQueryResult<Record<string, DashboardFilterMapping>> => {
  const programId = useProgram().id;
  return useQuery({
    queryKey: ['dashboardFilters', dashboardId],
    queryFn: () =>
      fetchDashboardFilters({ programId, dashboardId }).then((res) => {
        return res.data
          .sort((a, b) => a.order - b.order)
          .reduce(
            (
              filterMap: Record<string, DashboardFilterMapping>,
              filter: DashboardFilterMapping
            ) => {
              const map = { ...filterMap };
              map[filter.name] = {
                name: filter.name,
                value: undefined,
                filter_type: filter.filter_type,
                filter_key: filter.filter_key,
                order: filter.order,
              };
              return map;
            },
            {}
          );
      }),
    staleTime: 2 * 60,
  });
};
