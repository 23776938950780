import * as React from 'react';
import { Permissions } from 'models/permissions';
import { LinkFlags, LinkSectionType, LinkType } from './constant';

const filterActive = (links: LinkType[]) => links.filter((link) => link.active);
const filterNonEmpty = (sections: LinkSectionType[]) =>
  sections.filter((section) => section.links.length > 0);

export const useLinkSections = (
  flags: LinkFlags,
  permissions: Permissions['permissions']
): LinkSectionType[] => {
  return React.useMemo(
    () =>
      filterNonEmpty([
        {
          title: 'Administration',
          links: filterActive([
            {
              title: 'Account',
              to: 'account',
              active:
                permissions.configureAccountAccess &&
                !flags.showCommunityDetailsUI,
              description: 'View and update your community name and slug',
            },
            {
              title: 'Activity Feed',
              to: 'activities',
              description: 'View and export Studio activity for the community',
              active:
                flags.showActivitiesUI &&
                permissions.configureActivityFeedAccess,
            },
            {
              title: 'Advocacy',
              to: 'advocacy',
              active:
                flags.showLegacyConfigure &&
                permissions.configureAdvocacyAccess &&
                !flags.showCommunityDetailsUI,
              description: 'Manage community name and hashtags',
            },
            {
              title: 'Community Details',
              to: 'community-details',
              active:
                permissions.configureAdvocacyAccess &&
                flags.showCommunityDetailsUI,
              description: 'Manage and edit the details for the community',
            },
            {
              title: 'Capabilities',
              to: 'capabilities',
              active: permissions.capabilitiesAccess,
              description: 'View the enabled features for the community',
            },
            {
              title: 'Feature Settings',
              to: 'feature-settings',
              active: flags.featureSettingsEnabled,
              description: 'Manage features for your community',
            },
            {
              title: 'Roles',
              to: 'roles',
              active:
                flags.showPermissionsUI &&
                (flags.showStandardRoles || flags.showCustomRoles) &&
                permissions.configureRoleAccess,
              description: 'Create and manage admin permissions and roles',
            },
            {
              title: 'Bulk Updates',
              to: 'bulk-updates',
              active:
                permissions.configureRoleAccess &&
                flags.bulkUpdatePermissionsEnabled,
              description: 'View status and details of bulk update processes',
            },
            {
              title: 'Domains',
              to: 'region-domains',
              active: flags.displayRegionalDomains,
              description:
                'View region-specific domain details for the Firstup API and Authentication services.',
            },
            {
              title: 'Program Search',
              to: 'program-search',
              active:
                permissions.programSearchAccess && flags.displayProgramSearch,
              description: 'Search programs across all Firstup regions.',
            },
            {
              title: 'Field Management',
              to: 'attribute-management',
              active:
                permissions.configureAttributeManagementAccess &&
                flags.personalizedFieldsFlagV2,
              description:
                'Manage user attributes and their default values for use within content',
            },
          ]),
        },
        {
          title: 'Content',
          links: filterActive([
            {
              title: 'Campaign Settings',
              to: 'campaign-settings',
              active:
                (flags.deliveryThresholdEnabled ||
                  flags.setChannelDefaultsEnabled ||
                  flags.engagementBoostDefaultEnabled) &&
                permissions.configureCampaignSettingsAccess,
              description: 'Configure campaign settings and defaults',
            },
            {
              title: 'Custom URLs',
              to: 'custom-urls',
              active: flags.customURLsEnabled && permissions.confContentAccess,
              description:
                'Create and manage custom URL links that campaigns can point to',
            },
            {
              title: flags.authorAliasesEnabled ? 'Aliases' : 'Email Aliases',
              to: 'email-alias',
              active: flags.authorAliasesEnabled
                ? permissions.configureAliasesAccess
                : permissions.confContentAccess,
              description: flags.authorAliasesEnabled
                ? 'Create and manage email and author aliases, for use in campaigns'
                : 'Create and manage email aliases, for use in campaigns',
            },
            {
              title: 'Feeds',
              to: 'feeds',
              active: permissions.confContentAccess || permissions.feedAccess,
              description:
                'Create and manage feeds to import content automatically from external sources',
            },
            {
              title: 'Initiatives',
              to: 'initiatives',
              active: permissions.confContentAccess,
              description:
                'Create and manage initiatives to track related campaigns',
            },
            {
              title: 'Language Guide',
              to: 'style-guide',
              active: flags.styleGuideEnabled && permissions.confContentAccess,
              description:
                'Define writing recommendations to help keep campaign content on-brand and inclusive',
            },
            {
              title: 'Topics',
              to: 'topics',
              active: permissions.confContentAccess || permissions.topicsAccess,
              description:
                'Create and manage topics to organize and deliver campaigns to members',
            },
            {
              title: 'User Exports',
              to: 'exports',
              active: permissions.confContentAccess,
              description: 'View a record of all user exports',
            },
            {
              title: 'User Imports',
              to: 'imports',
              active: permissions.confContentAccess,
              description:
                'Import users, and view or download previous user imports',
            },
            {
              title: 'Language Controls',
              to: 'language-controls',
              active:
                flags.captionTranscriptionEnabled &&
                permissions.languageControlsAccess,
              description:
                'Create and manage rules to guide auto-transcription and translation of videos',
            },
          ]),
        },
        {
          title: 'Connect',
          links: filterActive([
            {
              title: 'Workflows',
              to: 'workflows',
              active: flags.kaiframesEnabled && permissions.workflowsAccess,
              description:
                'Create and manage automated workflows to increase adoption and engagement',
            },
            {
              title: 'Workflow Templates',
              to: 'workflow-templates',
              active:
                flags.kaiframesEnabled && permissions.workflowTemplatesAccess,
              description:
                'Create and manage templates to help you create automated workflows',
            },
          ]),
        },
        {
          title: 'Experience',
          links: filterActive([
            {
              title: 'Assistant Mascot',
              to: 'assistant-mascot',
              active:
                flags.showLegacyConfigure &&
                permissions.configureExperienceSettingsAccess,
              description:
                'Manage and edit the Assistant icon to customize the experience',
            },
            {
              title: 'Branding',
              to: 'branding',
              active:
                flags.showLegacyConfigure &&
                permissions.configureExperienceSettingsAccess,
              description:
                'Manage and edit branding assets for the community to customize the experience',
            },
            {
              title: 'Custom Feed',
              to: 'custom-homepage',
              active:
                flags.showLegacyConfigure &&
                permissions.configureExperienceSettingsAccess,
              description:
                'Manage and edit the Custom Homepage to create an engaging experience',
            },
            {
              title: 'Microapps',
              to: 'microapps',
              active:
                flags.showLegacyConfigure &&
                permissions.configureMicroappsAccess &&
                flags.microappsEnabled,
              description:
                'Create and manage microapps to reach employees anywhere',
            },
            {
              title: 'Profile',
              to: 'profile',
              active:
                flags.tagUsersEnabled &&
                permissions.configureExperienceUserSearch,
              description: 'Configure profile and custom attributes',
            },
            {
              title: 'Shortcuts',
              to: 'shortcuts',
              active:
                permissions.configureExperienceSettingsAccess &&
                (flags.shortcutsEnabled || flags.showLegacyConfigure),
              description:
                'Create and manage shortcuts to customize the experience',
            },
          ]),
        },
        {
          title: 'Security',
          links: filterActive([
            {
              title: 'Legal',
              to: 'legal',
              active: permissions.securityAccess,
              description:
                'Manage and edit Terms of Service and Privacy Policy details',
            },
            {
              title: 'Session Options',
              to: 'session-options',
              active: permissions.securityAccess,
              description:
                'Manage and edit session options for experience users',
            },
            {
              title: 'User Access',
              to: `user-access`,
              active: flags.showLegacyConfigure && permissions.securityAccess,
              description: 'Manage sign-in options to ensure security',
            },
          ]),
        },
        {
          title: 'Integration Management',
          links: filterActive([
            {
              title: 'Box Knowledge Management',
              to: 'box-km',
              active:
                flags.boxIntegrationEnabled &&
                permissions.configureBoxKnowledgeManagementAccess,
              description:
                'Create and manage Box folders. Link an audience to folders.',
            },
            {
              title: 'Workday',
              to: 'workday',
              active: flags.mergeWorkdayEnabled,
              description: 'Configure the Workday integration.',
            },
          ]),
        },
      ]),
    [flags, permissions]
  );
};
